import React, { useEffect, useState, useCallback } from 'react';
import { MapContainer, TileLayer, Polygon, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { wktToGeoJSON } from '@terraformer/wkt';
import { Tooltip } from 'react-leaflet';
import { simplify } from '@turf/turf';

// Helper function to filter polygons based on map bounds
const filterPolygonsInView = (polygons, bounds) => {
  return polygons.filter(polygon => {
    return polygon.coordinates.some(coord => bounds.contains(coord));
  });
};

// Custom hook to handle map bounds change and polygon visibility
const useVisiblePolygons = (polygons) => {
  const [visiblePolygons, setVisiblePolygons] = useState([]);
  const map = useMap();

  const updateVisiblePolygons = useCallback(() => {
    const bounds = map.getBounds();
    const filteredPolygons = filterPolygonsInView(polygons, bounds);
    setVisiblePolygons(filteredPolygons);
  }, [map, polygons]);

  useEffect(() => {
    if (polygons.length > 0) {
      updateVisiblePolygons(); // Trigger filtering on initial load
    }
  }, [updateVisiblePolygons, polygons]);

  useEffect(() => {
    map.on('moveend', updateVisiblePolygons);
    map.on('zoomend', updateVisiblePolygons);
    return () => {
      map.off('moveend', updateVisiblePolygons);
      map.off('zoomend', updateVisiblePolygons);
    };
  }, [map, updateVisiblePolygons]);

  return visiblePolygons;
};

const FilteredPolygons = ({ polygons }) => {
  const visiblePolygons = useVisiblePolygons(polygons);

  const getPolygonStyle = useCallback((properties) => {
    const meanLdenNumeric = parseFloat(properties.mean_lden.replace(',', '.'));
    const maxLdenNumeric = parseFloat(properties.max_lden.replace(',', '.'));
    let fillColor;
    if (meanLdenNumeric  > 60) {
      fillColor = 'red'; // High noise level
    } else if (meanLdenNumeric  > 50) {
      fillColor = 'orange'; // Moderate noise level
    } else {
      fillColor = 'green'; // Low noise level
    }

    return {
      fillColor: fillColor,
      fillOpacity: 0.6,
      color: 'transparent',
      weight: properties.antal_boliger > 50 ? 2 : 1,
    };
  }, []);

  return visiblePolygons.map((polygon, index) => (
    <Polygon
      key={index}
      positions={polygon.coordinates}
      pathOptions={getPolygonStyle(polygon.properties)}
    >
      <Tooltip>
        <div>
          <strong>Gennemsnitligt støjniveau i dB:</strong> {polygon.properties.mean_lden}<br />
          <strong>Maksimalt støjniveau i dB:</strong> {polygon.properties.max_lden}<br />
          <strong>Antal beboere:</strong> {polygon.properties.sum_personer}<br />
        </div>
      </Tooltip>
    </Polygon>
  ));
};

const formatMeanLden = (meanLden) => {
  const numDigits = meanLden.toString().length;
 let formattedValue;
  if (numDigits === 10) {formattedValue = meanLden /     100000000;}
  else if (numDigits === 9) {formattedValue = meanLden / 10000000; }
  else if (numDigits === 8) {formattedValue = meanLden / 1000000; } 
  else if (numDigits === 7) {formattedValue = meanLden / 100000; }
  else if (numDigits === 6) {formattedValue = meanLden / 10000; }
  else if (numDigits === 5) {formattedValue = meanLden / 1000; } 
  else if (numDigits === 4) {formattedValue = meanLden / 100; }
  else {formattedValue = meanLden;}
  return formattedValue.toLocaleString('da-DK', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

};

function App() {
  const [polygons, setPolygons] = useState([]);

  useEffect(() => {
    let isMounted = true;
  
    fetch('./assets/RoadNoise3.json')
      .then(response => response.json())
      .then(data => {
        if (isMounted) {
          const parsedPolygons = data.map(item => {
            try {
              const geojson = wktToGeoJSON(item.wkb_geometry);
              const simplifiedGeoJSON = simplify(geojson, { tolerance: 0.001, highQuality: true });
              const coordinates = simplifiedGeoJSON.coordinates[0][0].map(coord => [coord[1], coord[0]]);

              const mean_lden = formatMeanLden(item.mean_lden);
              const max_lden = (item.max_lden /1000).toLocaleString('da-DK', { minimumFractionDigits: 2, maximumFractionDigits: 2 });;

              // console.log("MAX_LDEN:",max_lden)
              return {
                coordinates: coordinates,
                properties: {
                  mean_lden: mean_lden,
                  max_lden: max_lden,
                  antal_boliger: item.antal_boliger,
                  sum_personer: item.sum_personer,
                  fot_id: item.fot_id
                }
              };
            } catch (error) {
              console.error("Error converting WKT to GeoJSON:", error);
              return null;
            }
          }).filter(polygon => polygon);

          setPolygons(parsedPolygons);
        }
      })
      .catch(error => console.error("Error loading JSON:", error));
    
    return () => { isMounted = false; };
  }, []);

  return (
    <div>
      {/* <h1>Støjniveau For Københavnske Boliger</h1> */}
      <MapContainer center={[55.671, 12.562]} zoom={15} style={{ height: "100vh", width: "100vw" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <FilteredPolygons polygons={polygons} />
      </MapContainer>
    </div>
  );
}

export default App;
